// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
	display: grid;
	grid-template-rows: 90px auto;
	grid-template-columns: 190px auto;
	grid-template-areas: "header header"
		"menu board";

}

header, nav, header >  section > button, header > section > select {
	color: #f8f8f8;
}

header {
	background-color: green;
	grid-area: header;
}

nav {
	background-color: #272525;
	grid-area: menu;
	overflow-y: scroll;
    scrollbar-width: none;  /*  making menu scrollable, ITSER-17103  */
}

main {
	grid-area: board;
	box-sizing: border-box;
}

@media (max-width: 1100px) {
	.App {
		grid-template-columns: auto;
		grid-template-rows: min-content min-content auto;
		grid-template-areas: "header"
							 "menu"
							 "board";
	}

	nav {
		display: flex;
		flex: 1 1;
		overflow-x: auto;
	}

	.MuiListItemText-root {
		width: 100px;
	}
}`, "",{"version":3,"sources":["webpack://./src/components/LoggedInn.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,6BAA6B;CAC7B,iCAAiC;CACjC;cACa;;AAEd;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,uBAAuB;CACvB,iBAAiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,eAAe;CACf,kBAAkB;IACf,qBAAqB,GAAG,0CAA0C;AACtE;;AAEA;CACC,gBAAgB;CAChB,sBAAsB;AACvB;;AAEA;CACC;EACC,2BAA2B;EAC3B,gDAAgD;EAChD;;eAEa;CACd;;CAEA;EACC,aAAa;EACb,SAAO;EACP,gBAAgB;CACjB;;CAEA;EACC,YAAY;CACb;AACD","sourcesContent":[".App {\n\tdisplay: grid;\n\tgrid-template-rows: 90px auto;\n\tgrid-template-columns: 190px auto;\n\tgrid-template-areas: \"header header\"\n\t\t\"menu board\";\n\n}\n\nheader, nav, header >  section > button, header > section > select {\n\tcolor: #f8f8f8;\n}\n\nheader {\n\tbackground-color: green;\n\tgrid-area: header;\n}\n\nnav {\n\tbackground-color: #272525;\n\tgrid-area: menu;\n\toverflow-y: scroll;\n    scrollbar-width: none;  /*  making menu scrollable, ITSER-17103  */\n}\n\nmain {\n\tgrid-area: board;\n\tbox-sizing: border-box;\n}\n\n@media (max-width: 1100px) {\n\t.App {\n\t\tgrid-template-columns: auto;\n\t\tgrid-template-rows: min-content min-content auto;\n\t\tgrid-template-areas: \"header\"\n\t\t\t\t\t\t\t \"menu\"\n\t\t\t\t\t\t\t \"board\";\n\t}\n\n\tnav {\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\toverflow-x: auto;\n\t}\n\n\t.MuiListItemText-root {\n\t\twidth: 100px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
